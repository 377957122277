/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axiosInstance from "api/axiosInstance";
import MDButton from "components/MDButton";
import Tooltip from "@mui/material/Tooltip";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

export default function Data({ websiteId }) {
  const [userData, setUserData] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (page) {
      fetchData();
    }
  }, [page]); // Empty dependency array ensures that the effect runs only once

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `/contactForm?page=${page}&pageSize=${pageSize}&websiteId=${websiteId}`
      );

      if (response.data?.success) {
        setUserData(response.data?.data);
        setTotalCount(response.data?.totalCount);
      } else {
        console.error("Error fetching data:", response.data);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const deleteItem = async (id) => {
    const confirmed = window?.confirm("Are you sure want to delete?");

    if (confirmed) {
      const res = await axiosInstance.delete(`/contactForm/${id}`, {});

      if (res.data?.success) {
        toast.success(res?.data?.message);
        fetchData();
      }
    }
  };

  function getDateOnly(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  }

  const Author = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const Message = ({ message }) => (
    <Tooltip title={message} arrow>
      <MDTypography
        component="span"
        variant="caption"
        color="text"
        fontWeight="medium"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
          "&:hover": {
            color: "primary.main", // Change to the desired hover color
          },
        }}
      >
        {message.length > 30 ? `${message.slice(0, 30)}...` : message}
      </MDTypography>
    </Tooltip>
  );
  const Email = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );
  const rows = userData
    ? userData.map((user) => ({
        User: <Author name={user.name} />,
        Email: <Email title={user.email} />,
        Phone: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {user.phone}
          </MDTypography>
        ),
        Subject: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {user.subject}
          </MDTypography>
        ),
        Message: <Message message={user.message} />,
        Date: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {getDateOnly(user.createdAt)}
          </MDTypography>
        ),
        Action: (
          <MDButton variant="gradient" color="info" fullWidth onClick={() => deleteItem(user?._id)}>
            Delete
          </MDButton>
        ),
      }))
    : [];
  return {
    columns: [
      { Header: "User", accessor: "User", width: "20%", align: "left" },
      { Header: "Email", accessor: "Email", align: "left" },
      { Header: "Phone", accessor: "Phone", align: "center" },
      { Header: "Subject", accessor: "Subject", width: "30%", align: "center" },
      { Header: "Message", accessor: "Message", width: "30%", align: "center" },
      { Header: "Date", accessor: "Date", align: "center" },
      { Header: "Action", accessor: "Action", align: "center" },
    ],
    rows: rows,
    totalCount: totalCount,
    pagecount: pageSize,
    setPage: setPage,
    userData: userData,
  };
}
