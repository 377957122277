import React, { useMemo } from "react";
import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MDBox from "components/MDBox";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

function DataTable({ table, isSorted, noEndBorder, pageSize, totalCount, setPage }) {
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, page, setPageSize } =
    tableInstance;

  // Set the entries per page value based on the select value
  const handleChange = (event, value) => {
    setPage(value);
  };
  const lengthArr = [1, 2, 3, 4, 5, 6, 7];
  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => (
                <DataTableHeadCell
                  key={idx}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={
                    isSorted && column.isSorted ? (column.isSortedDesc ? "desc" : "asc") : false
                  }
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {data.length === 0 && ( // Check if data is loading or empty
            <TableRow style={{ textAlign: "center" }}>
              <td colSpan="100%">No data found</td>
            </TableRow>
          )}
          {data.length === 0 // Check if data is loading or empty
            ? lengthArr.map((len, index) => (
                <TableRow key={index}>
                  {columns.map((column, idx) => (
                    <DataTableBodyCell key={idx} align={column.align ? column.align : "left"}>
                      <Skeleton variant="text" width={50} height={20} />
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              ))
            : // Render data if available
              page.map((row, key) => {
                prepareRow(row);
                return (
                  <TableRow key={key} {...row.getRowProps()}>
                    {row.cells.map((cell, idx) => (
                      <DataTableBodyCell
                        key={idx}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
      {totalCount !== 0 && (
        <Stack spacing={2} className="mb-3 ms-3">
          <Pagination
            count={Math.ceil(totalCount / pageSize)} // Assuming each page has 10 entries, adjust accordingly
            variant="outlined"
            color="primary"
            onChange={handleChange}
          />
        </Stack>
      )}
    </TableContainer>
  );
}

DataTable.propTypes = {
  entriesPerPage: PropTypes.shape({
    defaultValue: PropTypes.number,
    entries: PropTypes.arrayOf(PropTypes.number),
  }),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.shape({
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default DataTable;
